import { Component, Vue, Prop, Ref } from "vue-property-decorator";
import {
  isEmpty,
  getSymbolCurrency,
  formatMoney,
  isPositiveNumber,
} from "@helpers";
import {
  MIN_AUTO_REFILL_THRESHOLD,
  MAX_AUTO_REFILL_THRESHOLD,
  MIN_AUTO_REFILL_AMOUNT,
  MAX_AUTO_REFILL_AMOUNT,
} from "@config/base";

import { cardModule } from "@store/namespaces";
import { CardActions } from "@store/modules/card/types";
import { VForm } from "@/types/vuetify";
import { Currency } from "@/types/currency";

@Component({
  inheritAttrs: false,
})
export default class AutoRefillForm extends Vue {
  @cardModule.Action("updateCard")
  private readonly updateCardAction!: CardActions["updateCard"];

  @Ref("form") private readonly formRef!: VForm;
  @Prop({ type: Boolean, default: false }) private readonly disabled!: boolean;
  @Prop({ type: Number, required: true }) private readonly cardId!: number;
  @Prop({ type: String, required: true })
  private readonly currency!: Currency;
  @Prop({ type: Number })
  private readonly autoRefillAmount?: number;
  @Prop({ type: Number })
  private readonly autoRefillThreshold?: number;
  @Prop({ type: Boolean })
  private readonly autoRefillEnabled?: boolean;

  private loading = false;
  private form: {
    autoRefillAmount: string;
    autoRefillThreshold: string;
    autoRefillEnabled: boolean;
  } = {
    autoRefillAmount: "",
    autoRefillThreshold: "",
    autoRefillEnabled: false,
  };

  private get title() {
    return this.$vuetify.lang.t("$vuetify.dashboard.auto_refill.title");
  }

  private get currentSymbolCurrency() {
    return getSymbolCurrency(this.currency);
  }

  private get currentCurrencyIcon() {
    return require(`@/assets/img/currency/flags/${this.currency}.svg`);
  }

  private get fieldRules() {
    return {
      required: (v: string) =>
        !isEmpty(v) || this.$vuetify.lang.t("$vuetify.errors.required"),
      positiveNumber: (v: string) =>
        isPositiveNumber(v) ||
        this.$vuetify.lang.t("$vuetify.errors.positive_number"),
      autoRefillThreshold: (v: string) => {
        if (+v > MAX_AUTO_REFILL_THRESHOLD) {
          return this.$vuetify.lang.t(
            "$vuetify.errors.max_amount",
            formatMoney({
              value: MAX_AUTO_REFILL_THRESHOLD,
              currency: this.currency,
            })
          );
        }

        if (+v < MIN_AUTO_REFILL_THRESHOLD) {
          return this.$vuetify.lang.t(
            "$vuetify.errors.min_amount",
            formatMoney({
              value: MIN_AUTO_REFILL_THRESHOLD,
              currency: this.currency,
            })
          );
        }

        return true;
      },
      autoRefillAmount: (v: string) => {
        if (+v < MIN_AUTO_REFILL_AMOUNT) {
          return this.$vuetify.lang.t(
            "$vuetify.errors.min_amount",
            formatMoney({
              value: MIN_AUTO_REFILL_AMOUNT,
              currency: this.currency,
            })
          );
        }

        if (+v > MAX_AUTO_REFILL_AMOUNT) {
          return this.$vuetify.lang.t(
            "$vuetify.errors.max_amount",
            formatMoney({
              value: MAX_AUTO_REFILL_AMOUNT,
              currency: this.currency,
            })
          );
        }

        return true;
      },
    };
  }

  private onCancel() {
    this.$emit("cancel");
  }

  private async onSubmitForm() {
    if (this.loading || !this.formRef.validate()) return;

    this.loading = true;

    try {
      await this.updateCardAction({
        id: this.cardId,
        autoRefillAmount: this.form.autoRefillEnabled
          ? +this.form.autoRefillAmount
          : null,
        autoRefillThreshold: this.form.autoRefillEnabled
          ? +this.form.autoRefillThreshold
          : null,
        autoRefillEnabled: this.form.autoRefillEnabled,
      });

      this.loading = false;
      this.$emit("success");
    } catch (error) {
      this.loading = false;
    }
  }

  private created() {
    this.$watch(
      () =>
        [
          this.autoRefillAmount,
          this.autoRefillEnabled,
          this.autoRefillThreshold,
        ].join("-"),
      () => {
        this.form.autoRefillEnabled = this.autoRefillEnabled ?? false;

        this.form.autoRefillAmount =
          this.autoRefillAmount?.toString() ||
          MIN_AUTO_REFILL_AMOUNT.toString();

        this.form.autoRefillThreshold =
          this.autoRefillThreshold?.toString() ||
          MIN_AUTO_REFILL_THRESHOLD.toString();
      },
      {
        immediate: true,
      }
    );
  }
}
