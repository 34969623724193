var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.onSubmitForm.apply(null, arguments)}}},[_c('header',{staticClass:"d-flex justify-space-between align-center mb-2"},[_vm._t("title",null,{"title":_vm.title}),_c('v-checkbox',{staticClass:"ml-1 my-2 pt-0",attrs:{"hide-details":""},model:{value:(_vm.form.autoRefillEnabled),callback:function ($$v) {_vm.$set(_vm.form, "autoRefillEnabled", $$v)},expression:"form.autoRefillEnabled"}})],2),_c('aside',[_c('FormField',{attrs:{"required":_vm.form.autoRefillEnabled,"disabled":!_vm.form.autoRefillEnabled},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.dashboard.auto_refill.threshold"))+" ")]},proxy:true}])},[_c('v-text-field',{attrs:{"value":_vm.form.autoRefillEnabled ? _vm.form.autoRefillThreshold : '',"rules":_vm.form.autoRefillEnabled
            ? [
                _vm.fieldRules.required,
                _vm.fieldRules.positiveNumber,
                _vm.fieldRules.autoRefillThreshold,
              ]
            : undefined,"disabled":!_vm.form.autoRefillEnabled,"outlined":"","dense":"","inputmode":"decimal"},on:{"input":function($event){_vm.form.autoRefillThreshold = $event}},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('div',{staticClass:"d-flex align-center pr-1",style:({ paddingTop: '2px' })},[_c('BaseImg',{staticClass:"flex-shrink-0 flex-grow-0",attrs:{"src":_vm.currentCurrencyIcon,"width":"20"}}),_c('span',{staticClass:"black--text ml-1 text-caption-1"},[_vm._v(" "+_vm._s(_vm.currentSymbolCurrency)+" ")])],1)]},proxy:true}])})],1),_c('FormField',{attrs:{"required":_vm.form.autoRefillEnabled,"disabled":!_vm.form.autoRefillEnabled},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.fields.deposit_amount"))+" ")]},proxy:true}])},[_c('v-text-field',{attrs:{"value":_vm.form.autoRefillEnabled ? _vm.form.autoRefillAmount : '',"rules":_vm.form.autoRefillEnabled
            ? [
                _vm.fieldRules.required,
                _vm.fieldRules.positiveNumber,
                _vm.fieldRules.autoRefillAmount,
              ]
            : undefined,"disabled":!_vm.form.autoRefillEnabled,"outlined":"","dense":"","inputmode":"decimal"},on:{"input":function($event){_vm.form.autoRefillAmount = $event}},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('div',{staticClass:"d-flex align-center pr-1",style:({ paddingTop: '2px' })},[_c('BaseImg',{staticClass:"flex-shrink-0 flex-grow-0",attrs:{"src":_vm.currentCurrencyIcon,"width":"20"}}),_c('span',{staticClass:"black--text ml-1 text-caption-1"},[_vm._v(" "+_vm._s(_vm.currentSymbolCurrency)+" ")])],1)]},proxy:true}])})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"loading":_vm.loading,"ripple":false,"block":"","type":"submit","color":"primary","height":"40"}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.dashboard.save"))+" ")])],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"ripple":false,"outlined":"","block":"","type":"button","color":"error","height":"40"},on:{"click":_vm.onCancel}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.dashboard.close"))+" ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }